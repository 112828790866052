
import {
  FacebookIcon,
  InstagramIcon
} from 'vue-feather-icons'
import { mapGetters } from 'vuex'

export default {
  components: {
    FacebookIcon,
    InstagramIcon
  },
  computed: {
    ...mapGetters({
      setting: 'settings/setting'
    })
  }
}
